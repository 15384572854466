export const ALL_BLOG_REQUEST = "ALL_BLOG_REQUEST";
export const ALL_BLOG_SUCCESS = "ALL_BLOG_SUCCESS";
export const ALL_BLOG_FAIL = "ALL_BLOG_FAIL";

export const ADMIN_BLOG_REQUEST = "ADMIN_BLOG_REQUEST";
export const ADMIN_BLOG_SUCCESS = "ADMIN_BLOG_SUCCESS";
export const ADMIN_BLOG_FAIL = "ADMIN_BLOG_FAIL";

export const NEW_BLOG_REQUEST = "NEW_BLOG_REQUEST";
export const NEW_BLOG_SUCCESS = "NEW_BLOG_SUCCESS";
export const NEW_BLOG_RESET = "NEW_BLOG_RESET";
export const NEW_BLOG_FAIL = "NEW_BLOG_FAIL";

export const UPDATE_BLOG_REQUEST = "UPDATE_BLOG_REQUEST";
export const UPDATE_BLOG_SUCCESS = "UPDATE_BLOG_SUCCESS";
export const UPDATE_BLOG_RESET = "UPDATE_BLOG_RESET";
export const UPDATE_BLOG_FAIL = "UPDATE_BLOG_FAIL";

export const DELETE_BLOG_REQUEST = "DELETE_BLOG_REQUEST";
export const DELETE_BLOG_SUCCESS = "DELETE_BLOG_SUCCESS";
export const DELETE_BLOG_RESET = "DELETE_BLOG_RESET";
export const DELETE_BLOG_FAIL = "DELETE_BLOG_FAIL";

export const BLOG_DETAILS_REQUEST = "BLOG_DETAILS_REQUEST";
export const BLOG_DETAILS_SUCCESS = "BLOG_DETAILS_SUCCESS";
export const BLOG_DETAILS_FAIL = "BLOG_DETAILS_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
